/* eslint-disable linebreak-style */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable import/extensions */

import React from "react";
import BrandIcon from "./BrandIcon";
import LinkedInLogo from "../assets/icons/linkedin-32.png";
import GithubInLogo from "../assets/icons/github-32.png";

export default function Footer() {
  return (
    <div className="bg-gray-50 border-t border-gray-200 pb-6">
      <div className="container flex-col mx-auto">
        <div className="flex flex-col sm:flex-row mt-8 justify-center">
          <div className="w-1/3 flex-col ml-16 mr-8">
            <BrandIcon />
            <p className="w-full text-lg text-gray-400 font-light">
              Growing Your Business <br />
              Is Our Calling
            </p>
            {/* Flex container for LinkedIn and GitHub logos */}
            <div className="flex mt-2">
              {/* LinkedIn Image and Link */}
              <a
                href="https://www.linkedin.com/company/raven-tech-software/"
                target="_blank"
                rel="noopener noreferrer"
                className="mr-2" // Adds space between the icons
              >
                <img
                  src={LinkedInLogo}
                  alt="LinkedIn"
                  className="w-8 h-8" // Adjust the size as needed
                />
              </a>
              {/* Github Image and Link */}
              <a
                href="https://github.com/RavenTechSolutions"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={GithubInLogo}
                  alt="Github"
                  className="w-8 h-8" // Adjust the size as needed
                />
              </a>
            </div>
          </div>
          <div className="w-1/3 mt-0 ml-16 mr-0 sm:ml-0 sm:mr-5">
            <h1 className="text-lg text-theme-blue pt-4 pb-2">Details</h1>
            <div className="flex items-center">
              <span className="text-gray-400 text-lg font-light mr-2">
                Email:
              </span>
              <a
                href="mailto:seth@raventech.software"
                className="text-lg text-theme-purple font-light"
              >
                seth@raventech.software
              </a>
            </div>
            <div className="flex items-center mt-2">
              <span className="text-gray-400 text-lg font-light mr-2">
                Tel:
              </span>
              <p className="text-lg text-theme-purple font-light">
                +27 76 414 4176
              </p>
            </div>
          </div>
        </div>
        <div className="flex-col text-center mt-7">
          <p className="text-lg text-gray-400 font-light">
            Copyright 2024 - All rights reserved - RavenTech
          </p>
          <div className="flex-row">
            <p className="inline-block text-lg text-gray-400 font-light">
              Made with &#x2764; by&nbsp; RavenTech
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
