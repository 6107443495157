/* eslint-disable linebreak-style */
/* eslint-disable import/extensions */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable-next-line no-unused-vars */

// import ProjectPage from "pages/ProjectPage";
// import TeamPage from "pages/TeamPage";
// import { ProjectDetailPage } from "pages/ProjectDetailPage";
import { Route, Routes } from "react-router-dom";

import { ThemeProvider } from "theme/ThemeContext.js";

import LandingPage from "pages/LandingPage";
import NotFoundPage from "pages/NotFoundPage";

import { DiscussProjectPage } from "pages/DiscussProjectPage";

import "./assets/css/styles.css";

function App() {
  return (
    <>
      <ThemeProvider>
        <Routes>
          <Route exact path="/" element={<LandingPage />} />
          {/* <Route path="/project" element={<ProjectPage />} /> */}
          {/* <Route exact path="/project/:id" element={<ProjectDetailPage />} /> */}
          {/* <Route exact path="/team" element={<TeamPage />} /> */}
          <Route
            exact
            path="/discuss-project"
            element={<DiscussProjectPage />}
          />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </ThemeProvider>
    </>
  );
}

export default App;
