/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
/* eslint-disable react/button-has-type */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useRef, useEffect, useState } from "react"; // Import useState for managing modal state
import { Fade } from "react-awesome-reveal";
import { Container } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import Modal from "../modals/ServiceModal.js"; // Import the Modal component

const CustomPrevArrow = (props) => {
  return (
    <button
      className="absolute left-[40%] bottom-[-6%] bg-theme-blue text-white rounded-full p-3 z-[999] w-[40px] h-[40px] flex items-center justify-center"
      onClick={props.onClick}
      aria-label="Previous"
    >
      <FontAwesomeIcon icon={faChevronLeft} size="lg" color="white" />
    </button>
  );
};

const CustomNextArrow = (props) => {
  return (
    <button
      className="absolute right-[40%] bottom-[-6%] bg-theme-blue text-white rounded-full p-3 z-[999] w-[40px] h-[40px] flex items-center justify-center"
      onClick={props.onClick}
      aria-label="Next"
    >
      <FontAwesomeIcon icon={faChevronRight} size="lg" color="white" />
    </button>
  );
};

export default function Service({ data }) {
  const sliderRef = useRef(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  //  const [isModalOpen, setModalOpen] = useState(false); // State to control modal visibility
  //  const [selectedItem, setSelectedItem] = useState(null); // State to hold the selected item

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const settings = {
    dots: true,
    infinite: false,
    speed: 700,
    slidesToShow: 3,
    slidesToScroll: 3,
    nextArrow: windowWidth >= 1025 ? <CustomNextArrow /> : null,
    prevArrow: windowWidth >= 1025 ? <CustomPrevArrow /> : null,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  // // Function to handle item click
  // const handleItemClick = (item) => {
  //   setSelectedItem(item); // Set the selected item
  //   setModalOpen(true); // Open the modal
  // };

  // useEffect(() => {
  //   if (isModalOpen) {
  //     document.body.style.overflow = "hidden"; // Disable scrolling
  //   } else {
  //     document.body.style.overflow = "unset"; // Re-enable scrolling
  //   }

  //   // Cleanup effect to reset overflow when component unmounts
  //   return () => {
  //     document.body.style.overflow = "unset"; // Ensure scrolling is enabled
  //   };
  // }, [isModalOpen]);

  return (
    <div className="bg-gray-50">
      <div className="container mx-auto pt-20 pb-28">
        <Fade direction="right" triggerOnce>
          <h1 className="text-5xl text-theme-blue text-center font-bold">
            Our Services
          </h1>
        </Fade>
        <Fade direction="left" triggerOnce>
          <p className="font-light text-lg text-gray-400 text-center mb-12">
            We provide a full spectrum of software solutions tailored to meet
            your unique needs. Whatever your vision, we are here to bring it to
            life and help your business reach new heights.
          </p>
        </Fade>

        <Container>
          <div className="relative">
            <Slider ref={sliderRef} {...settings}>
              {data.map((item, index) => (
                <Fade direction={item.animation} key={index} triggerOnce>
                  <div className="m-7">
                    <div
                      className=" bg-white group rounded-2xl border border-light-theme-purple dark:border-dark-theme-purple transform transition duration-500 hover:scale-105 cursor-pointer"
                      // onClick={() => handleItemClick(item)} // Open modal on click
                    >
                      <img
                        src={item.imageUrl}
                        alt="Service"
                        className={`w-full rounded-t-2xl ${
                          ![
                            "Web Development",
                            "Mobile Development",
                            "UI/UX Design",
                          ].includes(item.title)
                            ? "padded-image"
                            : ""
                        }`}
                      />
                      <h2 className="text-theme-blue text-center text-l py-7 rounded-b-2xl m-2">
                        {item.title}
                      </h2>
                    </div>
                  </div>
                </Fade>
              ))}
            </Slider>
          </div>
        </Container>
      </div>
      {/* <Modal
        isOpen={isModalOpen}
        onClose={() => setModalOpen(false)}
        item={selectedItem}
      />{" "}
      Render the modal */}
    </div>
  );
}
