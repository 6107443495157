/* eslint-disable operator-linebreak */
/* eslint-disable import/extensions */

import React from "react";

// Services Img Imports
import Web from "../assets/images/Services/Web.jpeg";
import Mobile from "../assets/images/Services/Mobile.png";
import UIUX from "../assets/images/Services/Design.png";
import Backend from "../assets/images/Services/Backend.jpeg";
import Cloud from "../assets/images/Services/Cloud.jpeg";
import DevOps from "../assets/images/Services/DevOps.jpeg";
import AI from "../assets/images/Services/AI.jpeg";
import Analytics from "../assets/images/Services/Analytics.jpeg";
import QA from "../assets/images/Services/QA.jpeg";
import CSD from "../assets/images/Services/CSD.png";
import Strategy from "../assets/images/Services/Strategy.jpeg";
import Blockchain from "../assets/images/Services/Blockchain.jpeg";

// Portfolio Img Imports
import Recruiting from "../assets/images/Portfolio/Recruiting.png";
import Stream from "../assets/images/Portfolio/Stream.png";
import Freelance from "../assets/images/Portfolio/Freelance.png";
import Aura from "../assets/images/Portfolio/Aura.png";
import Surtido from "../assets/images/Portfolio/Surtido.png";
import ManagementApp from "../assets/images/Portfolio/ManagementApp.png";

// Advantages
import Communicative from "../assets/images/Advantages/Communicative.png";
import Collaborative from "../assets/images/Advantages/Collaborative.png";
import Management from "../assets/images/Advantages/Management.png";
import Favorite from "../assets/images/Advantages/Favorite.png";

// Testimonials
// import Sasha from "../assets/images/Testimonials/Sasha.jpg";
// import Reiner from "../assets/images/Testimonials/Reiner.jpg";
// import Kruger from "../assets/images/Testimonials/Kruger.jpg";

import logo from "../assets/images/Testimonials/logo.png";

// TeamMembers
import CEO from "../assets/images/TeamMembers/CEO.jpg";
import HRD from "../assets/images/TeamMembers/HRD.jpg";
import Finance from "../assets/images/TeamMembers/Finance.jpg";
import ProjectManager from "../assets/images/TeamMembers/Project-manager.jpg";
import Frontend1 from "../assets/images/TeamMembers/Frontend1.jpg";
import Frontend2 from "../assets/images/TeamMembers/Frontend2.jpg";
import Backend1 from "../assets/images/TeamMembers/Backend1.jpg";
import Backend2 from "../assets/images/TeamMembers/Backend2.jpg";
import Mobile1 from "../assets/images/TeamMembers/Mobile1.jpg";
import Mobile2 from "../assets/images/TeamMembers/Mobile2.jpg";
import UIUX1 from "../assets/images/TeamMembers/UIUX1.jpg";
import UIUX2 from "../assets/images/TeamMembers/UIUX2.jpg";

export const Services = [
  {
    title: "Web Development",
    imageUrl: Web,
    animation: "left",
    description: (
      <>
        <strong>
          Our web development services offer a comprehensive suite of solutions
          aimed at enhancing your digital presence.
          <br />
          <br />
          We specialize in creating custom websites tailored to meet your unique
          business objectives, we use the latest technologies and adhering to
          industry-leading best practices.
        </strong>
      </>
    ),
    title2: "Web Technologies and Libraries",
    tech: [
      {
        title: "Core Web Technologies",
        description:
          "HTML, CSS, and JavaScript for building the foundation of any modern web application.",
      },
      {
        title: "JavaScript Frameworks",
        description:
          "Efficient libraries like React and Next.js for building dynamic user interfaces and optimizing performance.",
      },
      {
        title: "CSS Frameworks",
        description:
          "Tools like Tailwind CSS for fast and flexible UI design with a utility-first approach.",
      },
      {
        title: "Utility Libraries",
        description:
          "Such as jQuery for DOM manipulation and handling web interactivity.",
      },
    ],
  },
  {
    title: "Mobile Development",
    imageUrl: Mobile,
    animation: "up",
    description: (
      <>
        <strong>
          Our mobile development services focus on creating high-performance,
          user-friendly applications tailored for both iOS and Android
          platforms.
          <br />
          <br />
          We leverage cutting-edge technologies and frameworks to deliver
          seamless mobile experiences that meet your business needs.
        </strong>
      </>
    ),
    title2: "Mobile Technologies and Frameworks",
    tech: [
      {
        title: "Cross-Platform Frameworks",
        description:
          "Efficient solutions like React Native and Flutter for building applications that run on multiple platforms.",
      },
      {
        title: "Native Development",
        description:
          "Swift for iOS and Kotlin for Android to create optimized, platform-specific applications.",
      },
      {
        title: "Backend Integration",
        description:
          "Connecting mobile apps to robust backend services and APIs for dynamic data handling.",
      },
    ],
  },
  {
    title: "UI/UX Design",
    imageUrl: UIUX,
    animation: "right",
    description: (
      <>
        <strong>
          Our UI/UX design services focus on creating intuitive and engaging
          user experiences that elevate your brand.
          <br />
          <br />
          We prioritize user-centric design principles to ensure that every
          interaction is seamless and enjoyable.
        </strong>
      </>
    ),
    title2: "Design Tools and Methodologies",
    tech: [
      {
        title: "Design Tools",
        description:
          "Industry-standard tools like Figma and Adobe XD for creating high-fidelity prototypes and designs.",
      },
      {
        title: "User Research",
        description:
          "Conducting thorough user research and testing to inform design decisions and enhance usability.",
      },
      {
        title: "Responsive Design",
        description:
          "Ensuring designs are adaptable across various devices and screen sizes for a consistent user experience.",
      },
    ],
  },
  {
    title: "Backend Development",
    imageUrl: Backend,
    animation: "down",
    description: (
      <>
        <strong>
          Our backend development services focus on building robust, scalable,
          and secure server-side applications that power your digital solutions.
          <br />
          <br />
          We utilize modern technologies and frameworks to ensure seamless data
          management and application performance.
        </strong>
      </>
    ),
    title2: "Backend Technologies and Frameworks",
    tech: [
      {
        title: "Server-Side Frameworks",
        description:
          "Efficient frameworks like Node.js, Django, and Ruby on Rails for efficient server-side logic and API development.",
      },
      {
        title: "Database Management",
        description:
          "Implementing relational and non-relational databases such as PostgreSQL, MySQL, and MongoDB for effective data storage and retrieval.",
      },
      {
        title: "API Development",
        description:
          "Creating RESTful and GraphQL APIs for seamless communication between the frontend and backend systems.",
      },
    ],
  },
  {
    title: "Cloud Solutions",
    imageUrl: Cloud,
    animation: "up",
    description: (
      <>
        <strong>
          Our cloud solutions empower your business with scalable, secure, and
          cost-effective services tailored to your specific needs.
          <br />
          <br />
          We leverage leading cloud platforms to enhance operational efficiency
          and ensure reliable data management.
        </strong>
      </>
    ),
    title2: "Cloud Technologies and Services",
    tech: [
      {
        title: "Cloud Platforms",
        description:
          "Implementing major providers like AWS, Azure, and Google Cloud for versatile hosting and infrastructure solutions.",
      },
      {
        title: "Data Storage",
        description:
          "Implementing scalable storage solutions such as object storage and databases for secure data management.",
      },
      {
        title: "Serverless Computing",
        description:
          "Leveraging services like AWS Lambda and Azure Functions for cost-effective, event-driven applications.",
      },
    ],
  },
  {
    title: "DevOps & Infrastructure",
    imageUrl: DevOps,
    animation: "left",
    description: (
      <>
        <strong>
          Our DevOps and infrastructure services streamline development
          processes and enhance operational efficiency through automation and
          best practices.
          <br />
          <br />
          We focus on creating robust, scalable infrastructures that support
          rapid deployment and continuous integration.
        </strong>
      </>
    ),
    title2: "DevOps Technologies and Practices",
    tech: [
      {
        title: "CI/CD Pipelines",
        description:
          "Implementing continuous integration and continuous deployment practices to automate testing and deployment processes.",
      },
      {
        title: "Containerization",
        description:
          "Leveraging Docker and Kubernetes for efficient application deployment and management across environments.",
      },
      {
        title: "Monitoring & Logging",
        description:
          "Employing tools like Prometheus and ELK Stack for real-time monitoring and analysis of system performance.",
      },
    ],
  },
  {
    title: "Artificial Intelligence",
    imageUrl: AI,
    animation: "down",
    description: (
      <>
        <strong>
          Our Artificial Intelligence services leverage advanced algorithms and
          machine learning techniques to drive innovation and enhance
          decision-making across your business.
          <br />
          <br />
          We develop AI solutions that automate processes, improve customer
          experiences, and provide valuable insights from data.
        </strong>
      </>
    ),
    title2: "AI Technologies and Applications",
    tech: [
      {
        title: "Machine Learning",
        description:
          "Adopting frameworks like TensorFlow and PyTorch to build predictive models and automate tasks.",
      },
      {
        title: "Natural Language Processing",
        description:
          "Implementing NLP techniques for sentiment analysis, chatbots, and language translation.",
      },
    ],
  },
  {
    title: "Blockchain Development",
    imageUrl: Blockchain,
    animation: "right",
    description: (
      <>
        <strong>
          Our blockchain development services focus on creating decentralized
          solutions that enhance transparency and security in transactions.
          <br />
          <br />
          We harness the power of blockchain technology to build innovative
          applications that meet the evolving needs of your business.
        </strong>
      </>
    ),
    title2: "Blockchain Technologies",
    tech: [
      {
        title: "Smart Contracts",
        description:
          "Making use of platforms like Ethereum to develop self-executing contracts for automated agreements.",
      },
      {
        title: "Decentralized Applications",
        description:
          "Building DApps that operate on a peer-to-peer network for enhanced security.",
      },
      {
        title: "Blockchain Protocols",
        description:
          "Implementing various blockchain frameworks for tailored solutions.",
      },
    ],
  },
  {
    title: "Data Analytics",
    imageUrl: Analytics,
    animation: "left",
    description: (
      <>
        <strong>
          Our data analytics services transform raw data into actionable
          insights that drive strategic business decisions.
          <br />
          <br />
          We employ advanced analytics techniques to help you understand trends,
          optimize operations, and enhance customer experiences.
        </strong>
      </>
    ),
    title2: "Analytics Tools and Techniques",
    tech: [
      {
        title: "Data Visualization",
        description:
          "Using tools like Tableau and Power BI to create interactive dashboards for real-time insights.",
      },
      {
        title: "Statistical Analysis",
        description:
          "Implementing statistical methods to uncover patterns and correlations in data.",
      },
      {
        title: "Big Data Technologies",
        description:
          "Employing Hadoop and Spark for processing large datasets efficiently.",
      },
    ],
  },
  {
    title: "IT Consulting & Strategy",
    imageUrl: Strategy,
    animation: "right",
    description: (
      <>
        <strong>
          Our IT Consulting & Strategy services help businesses navigate the
          complexities of technology to achieve their strategic goals.
          <br />
          <br />
          We offer expert guidance on technology implementation, digital
          transformation, and IT infrastructure optimization to drive
          operational efficiency and innovation.
        </strong>
      </>
    ),
    title2: "Consulting Services and Approaches",
    tech: [
      {
        title: "Technology Assessment",
        description:
          "Evaluating existing systems and technologies to identify opportunities for improvement and innovation.",
      },
      {
        title: "Digital Transformation",
        description:
          "Assisting organizations in transitioning to modern technologies and processes for enhanced performance.",
      },
      {
        title: "IT Strategy Development",
        description:
          "Crafting tailored IT strategies aligned with business objectives to ensure technology investments yield optimal returns.",
      },
    ],
  },
  {
    title: "Quality Assurance & Testing",
    imageUrl: QA,
    animation: "up",
    description: (
      <>
        <strong>
          Our Quality Assurance & Testing services ensure that your software
          meets the highest standards of quality, performance, and security.
          <br />
          <br />
          We implement rigorous testing methodologies to identify and address
          potential issues before deployment, ensuring a seamless user
          experience.
        </strong>
      </>
    ),
    title2: "Testing Methodologies and Tools",
    tech: [
      {
        title: "Automated Testing",
        description:
          "Applying tools like Selenium and JUnit for efficient regression testing and validation of software functionality.",
      },
      {
        title: "Manual Testing",
        description:
          "Conducting thorough manual testing processes to identify usability issues and ensure user satisfaction.",
      },
      {
        title: "Performance Testing",
        description:
          "Assessing software performance under various conditions to ensure reliability and scalability.",
      },
    ],
  },
  {
    title: "Custom Software",
    imageUrl: CSD,
    animation: "down",
    description: (
      <>
        <strong>
          Our Custom Software Development services are tailored to meet your
          specific business needs, delivering bespoke solutions that truly
          resonate with your operations.
          <br />
          <br />
          By harnessing cutting-edge technologies and innovative methodologies,
          we create enterprise-grade software designed to enhance efficiency and
          spur growth.
        </strong>
      </>
    ),
    title2: "Technologies for Custom Software Development",
    tech: [
      {
        title: "Microservices Architecture",
        description:
          "Building applications using microservices to enhance scalability, maintainability, and deployment flexibility.",
      },
      {
        title: "Domain-Driven Design (DDD)",
        description:
          "Employing DDD to ensure that the software model closely aligns with the business domain, facilitating better communication and results.",
      },
      {
        title: "DevOps Practices",
        description:
          "Integrating DevOps methodologies to streamline collaboration between development and operations, ensuring faster delivery cycles and high reliability.",
      },
      {
        title: "Continuous Integration/Continuous Deployment (CI/CD)",
        description:
          "Implementing CI/CD pipelines to automate the testing and deployment process, allowing for faster updates and consistent quality.",
      },
      {
        title: "Cloud-Native Development",
        description:
          "Creating cloud-native applications that are optimized for deployment in cloud environments, offering flexibility and resilience.",
      },
    ],
  },
];

export const Portfolios = [
  {
    id: "asd1293uasdads1",
    title: "Recruiting App",
    imageUrl: Recruiting,
    type: "Mobile Apps",
    responsibility: ["Mobile Development", "UI/UX Design"],
    credit: "https://dribbble.com/shots/15164950-Recruiting-app",
  },
  {
    id: "asd1293uhjkhkjh1",
    title: "Stream+",
    imageUrl: Stream,
    type: "Mobile Apps",
    responsibility: ["Mobile Development", "UI/UX Design"],
    credit: "https://dribbble.com/shots/15276430-Stream",
  },
  {
    id: "asd1293uvbvcbbd3",
    title: "Freelance",
    imageUrl: Freelance,
    type: "Mobile Apps",
    responsibility: ["Mobile Development", "UI/UX Design"],
    credit: "https://dribbble.com/shots/15223131-Freelance-Mobile-App-Concept",
  },
  {
    id: "asd1293ufgdfgs4",
    title: "Aura",
    imageUrl: Aura,
    type: "Website",
    responsibility: ["Web Development", "UI/UX Design"],
    credit: "https://dribbble.com/shots/15176338-Aura-Website-Main-Page",
  },
  {
    id: "asd1293ulskmnb5",
    title: "Surtido Rico",
    imageUrl: Surtido,
    type: "Website",
    responsibility: ["Web Development", "UI/UX Design"],
    credit: "https://dribbble.com/shots/15173118-Surtido-Rico",
  },
  {
    id: "asd1293ulkmnbj6",
    title: "Courses Management",
    imageUrl: ManagementApp,
    type: "Website",
    responsibility: ["Web Development", "UI/UX Design"],
    credit:
      "https://dribbble.com/shots/15197890-Courses-Management-and-Promoting-Web-App",
  },
];

export const Advantages = [
  [
    {
      title: "Effective Communication",
      description:
        "We ensure transparent and clear communication regarding project ideas and progress, providing our clients with a comprehensive understanding of our work.",
      imageUrl: Communicative,
    },
    {
      title: "Strategic Management",
      description:
        "Our meticulous project management approach ensures that every aspect of the project is executed efficiently and to the highest standards.",
      imageUrl: Management,
    },
  ],
  [
    {
      title: "Collaborative Approach",
      description:
        "Our team thrives on collaboration, working cohesively to achieve project goals and deliver exceptional results.",
      imageUrl: Collaborative,
    },
    {
      title: "Commitment to Excellence",
      description:
        "We are dedicated to delivering exceptional results and are committed to exceeding client expectations with every project we undertake.",
      imageUrl: Favorite,
    },
  ],
];

export const Testimonials = [
  {
    id: 1,
    name: "Sasha Rose",
    company: "Owner, Placeholder Company",
    testimoni:
      "Thanks for RavenTech , you guys are the best! Keep up the great work!",
    imageUrl: logo,
  },
  {
    id: 2,
    name: "Kruger Khan",
    company: "Director, Placeholder Company",
    testimoni:
      "I just wanted to let you know that it’s been great working with RavenTech.",
    imageUrl: logo,
  },
  {
    id: 3,
    name: "Reiner John",
    company: "CEO, Placeholder Company",
    testimoni: "RavenTech is so great. Thank you so much for a job well done.",
    imageUrl: logo,
  },
];

export const TeamMembers = [
  {
    name: "Rach David",
    position: "CEO",
    imageUrl: CEO,
  },
  {
    name: "Pauline Sydney",
    position: "HRD",
    imageUrl: HRD,
  },
  {
    name: "Granger Watterson",
    position: "Finance",
    imageUrl: Finance,
  },
  {
    name: "Tom Jimmy",
    position: "Project Manager",
    imageUrl: ProjectManager,
  },
  {
    name: "Jim Hendrix",
    position: "Front-end Developer",
    imageUrl: Frontend1,
  },
  {
    name: "Calvin Max",
    position: "Front-end Developer",
    imageUrl: Frontend2,
  },
  {
    name: "Hawkins Jim",
    position: "Back-end Developer",
    imageUrl: Backend1,
  },
  {
    name: "Don Bizaro",
    position: "Back-end Developer",
    imageUrl: Backend2,
  },
  {
    name: "Bill Markinson",
    position: "Mobile Developer",
    imageUrl: Mobile1,
  },
  {
    name: "Igor Kavarov",
    position: "Mobile Developer",
    imageUrl: Mobile2,
  },
  {
    name: "Freddie Curl",
    position: "UI/UX Designer",
    imageUrl: UIUX2,
  },
  {
    name: "Monica Lovegood",
    position: "UI/UX Designer",
    imageUrl: UIUX1,
  },
];
